@font-face {
  font-family: 'mastodon-font-display';
  src: local('Spectral'),
    url('../fonts/montserrat/lib-reg.woff2') format('woff2'),
    url('../fonts/montserrat/lib-reg.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'mastodon-font-display';
  src: local('Spectral'),
    url('../fonts/montserrat/lib-bold.woff2') format('woff2'),
    url('../fonts/montserrat/lib-bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'mastodon-font-display';
  src: local('Spectral'),
    url('../fonts/montserrat/lib-italic.woff2') format('woff2'),
    url('../fonts/montserrat/lib-italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'mastodon-font-display';
  src: local('Spectral'),
    url('../fonts/montserrat/lib-bolditalic.woff2') format('woff2'),
    url('../fonts/montserrat/lib-bolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
