// Dependent colors
$black: #000000;
$white: #ffffff;

$classic-base-color: #303030;
$classic-primary-color: #b3b3b3;
$classic-secondary-color: #e0e0e0;
$classic-highlight-color: #000000;

// Differences
$success-green: lighten(#3c754d, 8%);

$base-overlay-background: $white !default;
$valid-value-color: $success-green !default;

$ui-base-color: $classic-secondary-color !default;
$ui-base-lighter-color: #bfbfbf;
$ui-primary-color: #c4c4c4;
$ui-secondary-color: $classic-base-color !default;
$ui-highlight-color: #000000;

$primary-text-color: $black !default;
$darker-text-color: $classic-base-color !default;
$dark-text-color: #525252;
$action-button-color: #737373;

$inverted-text-color: $black !default;
$lighter-text-color: $classic-base-color !default;
$light-text-color: #525252;

//Newly added colors
$account-background-color: $white !default;

//Invert darkened and lightened colors
@function darken($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) + $amount);
}

@function lighten($color, $amount) {
  @return hsl(hue($color), saturation($color), lightness($color) - $amount);
}

$emojis-requiring-inversion: 'chains';
